<template>
  <vue-html2pdf
    :show-layout="true"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    filename="extrato_de_transação"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="100%"
    ref="html2Pdf"
  >
    <section slot="pdf-content" v-if="invoice.type == 'invoice'">
      <q-banner inline-actions class="text-white bg-red text-center" v-if="false">
        Invoice cancelada ou inexistente
      </q-banner>

      <q-page class="flex flex-center bg-grey">
        <q-card flat class="bg-white" style="width: 800px">
          <q-card-section class="row">
            <div class="col-6">
              <q-img :src="require('@/assets/images/logo-afilio.png')" style="width: 150px" />
              <q-item>
                <q-item-section>
                  <q-item-label class="q-mb-sm" caption>HI-MIDIA INTERNET LTDA.</q-item-label>
                  <q-item-label class="q-mb-sm" caption>
                    RUA HELIOS SEELINGER - RIO DE JANEIRO -155 - SALA 302 -RJ
                  </q-item-label>
                  <q-item-label class="q-mb-sm" caption>CEP 22640-040</q-item-label>
                  <q-item-label class="q-mb-sm" caption>TELEFONE (21) 2244-8850</q-item-label>
                  <q-item-label class="q-mb-sm" caption>FAX 2122448850</q-item-label>
                  <q-item-label class="q-mb-sm" caption>Inscr. CNPJ: 07.456.778/0001-59</q-item-label>
                  <q-item-label class="q-mb-sm" caption>Inscr. Estadual: ISENTO</q-item-label>
                  <q-item-label class="q-mb-sm" caption>Inscr. Municipal: 0.443.243-6</q-item-label>

                  <q-item-label caption lines="3"
                    >Rua Humaita, 275 - 6º andar/7º andar - Humaita - Cep: 22.261-005 Rio de Janeiro - RJ Brasil
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
            <div class="col-6 text-right">
              <q-list bordered separator>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none"> <q-item-label caption> Operação:</q-item-label> </q-item-section>

                  <q-item-section> LOCAÇÃO DE ESPAÇO VIRTUAL </q-item-section>
                </q-item>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none">
                    <q-item-label caption>Fatura nº:</q-item-label>
                  </q-item-section>
                  <q-item-section> {{ invoice.id }} </q-item-section>
                </q-item>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none">
                    <q-item-label caption>Emissão:</q-item-label>
                  </q-item-section>
                  <q-item-section> {{ formatDate(invoice.emission_date) }}</q-item-section>
                </q-item>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none">
                    <q-item-label caption>Vencimento: </q-item-label>
                  </q-item-section>
                  <q-item-section>{{ formatDate(invoice.due_date) }}</q-item-section>
                </q-item>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none">
                    <q-item-label caption>Moeda: </q-item-label>
                  </q-item-section>
                  <q-item-section>{{ invoice.currency }}</q-item-section>
                </q-item>
                <q-item class="q-pa-md">
                  <q-item-section class="q-pa-none">
                    <q-item-label caption>Valor a pagar: </q-item-label>
                  </q-item-section>
                  <q-item-section>{{ invoice.value_to_invoice }}</q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="q-my-md col-12">
              <q-card flat bordered>
                <q-card-section horizontal>
                  <q-card-section>
                    <div class="text-caption text-grey">SACADO:</div>
                    {{ invoice.adv.business_name }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section bordered>
                    <div class="text-caption text-grey">CNPJ/CPF:</div>
                    <q-input
                      hide-bottom-space
                      dense
                      class="q-pa-none"
                      mask="##.###.###/####-##"
                      input-style="padding: 0px;"
                      borderless
                      readonly
                      :value="invoice.adv.cnpj"
                    />
                    <!-- {{ invoice.adv.cnpj }} -->
                  </q-card-section>
                </q-card-section>

                <q-separator />

                <q-card-section>
                  <div class="text-caption text-grey">ENDEREÇO:</div>
                  <!-- AV TUCUNARE . 550 - TAMBORE -->
                  {{ invoice.adv.address + ' ' + invoice.adv.address_number }}
                </q-card-section>

                <q-separator />

                <q-card-section class="row" horizontal>
                  <q-card-section class="col-4">
                    <div class="text-caption text-grey">CEP:</div>
                    <q-input
                      hide-bottom-space
                      dense
                      class="q-pa-none"
                      mask="#####-###"
                      input-style="padding: 0px;"
                      borderless
                      readonly
                      :value="invoice.adv.zip_code"
                    />
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="col-4" bordered>
                    <div class="text-caption text-grey">FONE:</div>

                    <q-input
                      hide-bottom-space
                      dense
                      class="q-pa-none"
                      mask="(##) #####-####"
                      input-style="padding: 0px;"
                      borderless
                      readonly
                      :value="invoice.adv.phone_number"
                    />
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="col-4" bordered>
                    <div class="text-caption text-grey">CONTATO:</div>
                    {{ invoice.adv.contact_email }}
                  </q-card-section>
                </q-card-section>

                <q-separator />

                <q-card-section class="row" horizontal>
                  <q-card-section class="col-4">
                    <div class="text-caption text-grey">CIDADE:</div>
                    {{ invoice.adv.city }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="col-4" bordered>
                    <div class="text-caption text-grey">ESTADO:</div>
                    {{ invoice.adv.state }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="col-4" bordered>
                    <div class="text-caption text-grey">PAÍS:</div>
                    {{ invoice.adv.country }}
                  </q-card-section>
                </q-card-section>

                <q-separator />

                <q-card-section class="" horizontal>
                  <q-card-section class="">
                    <div class="text-caption text-grey">DATA EMISSÃO:</div>
                    {{ formatDate(invoice.emission_date) }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="" bordered>
                    <div class="text-caption text-grey">Nº FATURA:</div>
                    {{ invoice.id }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="" bordered>
                    <div class="text-caption text-grey">VALOR:</div>
                    {{ invoice.value_to_invoice }}
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="" bordered>
                    <div class="text-caption text-grey">FORMA DE COBRANÇA:</div>
                    PG A VISTA NACIONAL
                  </q-card-section>
                  <q-separator vertical />
                  <q-card-section class="" bordered>
                    <div class="text-caption text-grey">VENCIMENTO:</div>
                    {{ formatDate(invoice.due_date) }}
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </q-card-section>
          <q-separator spaced inset />
          <q-card-section class="q-px-xl">
            <q-table
              :columns="[
                { name: 'description', field: 'descripiton', label: 'Desrição' },
                { name: 'quantity', field: 'quantity', label: 'QTDE' },
                { name: 'cost_center', field: 'cost_center', label: 'Centro de Custo' },
                { name: 'unitary_value', field: 'unitary_value', label: 'Valor Unitário' }
              ]"
              :data="invoice.services"
              flat
              hide-pagination
              table-header-class="text-grey"
              bordered
              separator="cell"
            >
              <template v-slot:body-cell-description="props">
                <q-td>{{ servicesArr.find((service) => service.type == props.row.type).label }}</q-td>
              </template>
            </q-table>
          </q-card-section>

          <q-separator spaced inset />

          <q-card-section class="q-px-xl">
            <div class="text-caption text-grey">OBSERVAÇÃO:</div>
            {{ invoice.observation }}
          </q-card-section>
        </q-card>
      </q-page>

      <q-banner inline-actions class="text-white bg-red text-center" v-if="invoiceError">
        Invoice cancelada ou inexistente
      </q-banner>
    </section>

    <section v-else-if="invoice.type == 'nfs'" slot="pdf-content">
      <q-page class="flex flex-center bg-grey q-pa-lg">
        <q-card flat class="bg-white" style="width: 800px; min-height: 1000px"
          ><q-card-section class="row">
            <q-list class="col-12 row" bordered>
              <q-item class="col-7 col">Logo</q-item>
              <q-separator vertical />
              <q-item class="col-4 col">Informações laterais</q-item>
            </q-list>
          </q-card-section></q-card
        >
      </q-page>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import FinanceService from '@/services/FinanceService';

export default {
  components: { VueHtml2pdf },
  mixins: [FinanceService],
  name: 'showAdvertiserInvoicePDF',
  props: {
    id: String
  },
  data() {
    return {
      invoiceError: null,
      invoice: {},
      servicesArr: [
        { label: 'Locação de espaço virtual nacional', type: 0 },
        { label: 'Locação d eespaço virtual exterior', type: 1 },
        { label: 'CPC nacional', type: 2 },
        { label: 'CPA Nacional', type: 3 },
        { label: 'CPL Nacional', type: 4 },
        { label: 'Mobile nacional', type: 5 },
        { label: 'Latam Nacional', type: 6 },
        { label: 'CPC Internacional', type: 7 },
        { label: 'CPA Internacional', type: 8 },
        { label: 'CPL Internacional', type: 9 },
        { label: 'Mobile Internacional', type: 10 },
        { label: 'LATAM Internacional', type: 11 }
      ]
    };
  },
  methods: {
    async retrieveInvoiceData() {
      try {
        this.onLoading(true);
        const { data } = await this.showAdvertiserInvoice(this.id);
        this.invoice = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        this.$router.push({ name: 'AdvertiserInvoices' });
      } finally {
        this.onLoading(false);
      }
    },

    formatDate(date) {
      let splitedDate = date.split('-');
      return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
    }
  },

  created() {
    this.retrieveInvoiceData();
  }
};
</script>

<style></style>
