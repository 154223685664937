import { render, staticRenderFns } from "./ShowAdvertiserInvoicePDF.vue?vue&type=template&id=4a979b8c"
import script from "./ShowAdvertiserInvoicePDF.vue?vue&type=script&lang=js"
export * from "./ShowAdvertiserInvoicePDF.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QList from 'quasar/src/components/item/QList.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QPage,QCard,QCardSection,QImg,QItem,QItemSection,QItemLabel,QList,QSeparator,QInput,QTable,QTd});
